import React from 'react';
import {
  Intro,
  Schrodinger,
  ManyWorlds,
  ManyQuestions,
  End,
} from '../components';
import useHasMounted from '../hooks/useHasMounted';
import './index.scss';

const Homepage = () => {
  const hasMounted = useHasMounted();
  console.log({ hasMounted });
  return (
    <main className={`page${hasMounted ? ' mounted' : ''}`} style={{ opacity: 0 }}>
      <Intro />
      <Schrodinger />
      <ManyWorlds />
      <ManyQuestions />
      <End />
    </main>
  );
};

export default Homepage;
